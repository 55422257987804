import { Fragment, FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Field, Formik, FormikProps } from 'formik'
import { z, ZodIssueBase } from 'zod'

import {
  ContentPage,
  ContentPageWrapper,
  InputError,
  InputForm,
  InputItem,
  InputRow,
  NoResultsContainer,
  SearchIcon,
  SearchResult,
  SkipServiceContainer,
  SkipServiceField
} from '@components/screen/styles'
import ContinueButtonCircular from '@components/screen/continueButtonCircular'
import { useSetup } from '@components/context/setupProvider'
import steps from '@utils/steps'
import { LoadingSpinner } from '@components/screen/loadingSpinner'
import { MobileFooter } from '@components/screen/mobileFooter'
import { mediaBreakpoints } from '@components/globalStyles'

export type Undertaker = {
  hubspotId: string | null
  name: string
  street?: string
  postalCode: string
  distance?: number
  city: string
}

const SearchResultItem: FunctionComponent<{
  onClick: (undertaker: Undertaker) => void
  undertaker: Undertaker
  checked: boolean
}> = ({ onClick, undertaker, checked }) => {
  return (
    <SearchResult onClick={() => onClick(undertaker)}>
      <div>
        <b>{undertaker.name}</b>
        <small>
          <span>{undertaker.distance?.toFixed(1)} km</span>
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 18C7 18 14 13.0909 14 7.36364C14 5.41068 13.2625 3.53771 11.9497 2.15676C10.637 0.775809 8.85652 0 7 0C5.14348 0 3.36301 0.775809 2.05025 2.15676C0.737498 3.53771 0 5.41068 0 7.36364C0 13.0909 7 18 7 18ZM10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z"
              fill="#B9B28C"
            />
          </svg>
          {undertaker.street}, {undertaker.postalCode} {undertaker.city}
        </small>
      </div>
      <ul>
        <input type="radio" name="test" id="" checked={checked} />
        <li />
      </ul>
    </SearchResult>
  )
}

const ServiceSearch: FunctionComponent = () => {
  const { setStep, personalData, undertaker, setUndertaker } = useSetup()

  const [query, setQuery] = useState(personalData.postalCode)
  const [serviceSelected, setServiceSelected] = useState<Undertaker | null>(undertaker)

  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<Undertaker[]>([])

  const [lastInput, setLastInput] = useState<number>(0)

  const [customUndertakerValid, setCustomUndertakerValid] = useState(false)
  const [customUndertaker, setCustomUndertaker] = useState<Undertaker | null>(null)

  const loadResults = async () => {
    setLoading(true)

    const result = await fetch(`${process.env.APIHOST}/undertaker/${query}`)
    const data = await result.json()

    setSearchResults(data)
    setLoading(false)
  }

  useEffect(() => {
    if (query.length < 3) return

    setServiceSelected(null)

    if (lastInput != 0) clearTimeout(lastInput)

    setLastInput(
      window.setTimeout(() => {
        setLastInput(0)
        loadResults()
      }, 200)
    )
  }, [query])

  useEffect(() => {
    setServiceSelected(undertaker)
  }, [])

  const validateForm = (values: Undertaker) => {
    const Form = z.object({
      name: z.string().nonempty('Bitte geben Sie einen Namen an'),
      postalCode: z.string().nonempty('Bitte geben Sie eine Postleitzahl an'),
      city: z.string().nonempty('Bitte geben Sie eine Stadt an')
    })

    const result = Form.safeParse(values)

    if (!result.success) {
      let errors: { [key: string]: any } = {}

      result.error.issues.forEach((issue: ZodIssueBase) => {
        if (!errors[issue.path[0]]) errors[issue.path[0]] = issue.message
      })

      setCustomUndertakerValid(false)
      return errors
    } else {
      setCustomUndertaker(values)
      setCustomUndertakerValid(true)
      return {}
    }
  }

  return (
    <ContentPageWrapper>
      <ContentPage>
        <h1>Bestatter in Ihrer Nähe</h1>
        <p>
          Sie haben die Möglichkeit einen unserer Partnerbestatter für die Reerdigung zu wählen.
        </p>
        <InputForm>
          <InputItem>
            <input
              type="text"
              placeholder="Postleitzahl oder Ort"
              value={query}
              onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
            />
            {loading ? (
              <LoadingSpinner strokeColor="var(--sage)" size={24} />
            ) : (
              <SearchIcon
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M19 19L14.65 14.65"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </SearchIcon>
            )}
          </InputItem>
          {searchResults.length > 0 ? (
            searchResults.map((item: Undertaker) => (
              <SearchResultItem
                undertaker={item}
                onClick={() => setServiceSelected(item)}
                checked={serviceSelected != null && serviceSelected.hubspotId == item.hubspotId}
              />
            ))
          ) : (
            <>
              <NoResultsContainer>
                <h2>Kein Partner in Ihrer Nähe</h2>
                <p>
                  Wir haben noch keinen Partner in Ihrer Nähe und freuen uns, wenn Sie uns einen
                  Bestatter Ihres Vertrauens vorschlagen:
                </p>
                <Formik
                  initialValues={{ city: '', postalCode: '', name: '', hubspotId: null }}
                  validate={validateForm}
                  validateOnBlur={false}
                  validateOnMount={true}
                  onSubmit={() => {}}>
                  {({ errors, touched, handleSubmit }: FormikProps<Undertaker>) => (
                    <>
                      <InputForm onSubmit={handleSubmit}>
                        <InputItem error={typeof errors.name === 'string' && touched.name}>
                          <Field name="name" placeholder="Name des Bestattungsinstituts" />
                          {errors.name && touched.name && <InputError>{errors.name}</InputError>}
                        </InputItem>
                        <InputRow columnBreakpoint={mediaBreakpoints.noBreakpoint}>
                          <InputItem
                            maxWidth="104px"
                            error={typeof errors.postalCode === 'string' && touched.postalCode}>
                            <Field name="postalCode" placeholder="PLZ" />
                          </InputItem>
                          <InputItem error={typeof errors.city === 'string' && touched.city}>
                            <Field name="city" placeholder="Ort" />
                          </InputItem>
                          {errors.postalCode && touched.postalCode && (
                            <InputError>{errors.postalCode}</InputError>
                          )}
                          {errors.city && touched.city && <InputError>{errors.city}</InputError>}
                        </InputRow>
                      </InputForm>
                    </>
                  )}
                </Formik>
              </NoResultsContainer>
            </>
          )}
        </InputForm>
        <MobileFooter />
        <ContinueButtonCircular
          active={serviceSelected != null || searchResults.length == 0}
          onClick={() => {
            if (!serviceSelected && customUndertakerValid) setUndertaker(customUndertaker)
            else if (serviceSelected) setUndertaker(serviceSelected)
            else setUndertaker(null)

            if (serviceSelected || searchResults.length == 0) setStep(steps[2])
          }}
        />
      </ContentPage>
      <SkipServiceContainer>
        <SkipServiceField
          onClick={() => {
            setUndertaker(null)
            setStep(steps[2])
          }}>
          Ohne Bestatter fortfahren
        </SkipServiceField>
      </SkipServiceContainer>
    </ContentPageWrapper>
  )
}

export default ServiceSearch

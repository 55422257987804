import { FunctionComponent, h } from 'preact'
import { ContinueButtonCircular as ContinueButtonCircularComponent } from './styles'

type ButtonProps = {
  active?: boolean
  onClick?: () => void
  href?: string
}

const SvgIcon: FunctionComponent = () => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18H32" stroke-width="4" />
    <path d="M16 2L32 18L16 34" stroke-width="4" />
  </svg>
)

const ContinueButtonCircular: FunctionComponent<ButtonProps> = ({
  active = false,
  onClick,
  href
}) => (
  <ContinueButtonCircularComponent active={active} onClick={onClick}>
    {href ? (
      <a href={href} rel="nofollow">
        <SvgIcon />
      </a>
    ) : (
      <SvgIcon />
    )}
  </ContinueButtonCircularComponent>
)

export default ContinueButtonCircular

import { ComponentChildren, h, createContext } from 'preact'
import { useContext, useEffect, useMemo, useState } from 'preact/hooks'

import steps, { Step } from '@utils/steps'
import { FormikValues as PersonalData } from '@routesBasic/personalData'
import { Undertaker } from '@routesBasic/serviceSearch'

type SetupProps = {
  currentStep: Step | null
  currentIndex: number
  personalData: PersonalData
  undertaker: Undertaker | null
  orderId: string | null
  pendingPayment: string | null

  setStep: (step: Step) => void
  setPersonalData: (data: PersonalData) => void
  setUndertaker: (data: Undertaker | null) => void
  setOrderId: (id: string | null) => void
  setPendingPayment: (id: string | null) => void
}

const userValues = {
  salutation: 'none',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  birthday: '',
  street: '',
  postalCode: '',
  city: '',
  country: 'none'
}

const undertakerValues: Undertaker = {
  hubspotId: null,
  name: '',
  postalCode: '',
  city: ''
}

const SetupContext = createContext<SetupProps>({
  currentStep: steps[0],
  currentIndex: 0,
  personalData: userValues,
  undertaker: undertakerValues,
  orderId: null,
  pendingPayment: null,

  setStep: (step: Step) => {},
  setPersonalData: (data: PersonalData) => {},
  setUndertaker: (data: Undertaker | null) => {},
  setOrderId: (id: string | null) => {},
  setPendingPayment: (id: string | null) => {}
})

export const SetupProvider = ({ children }: { children: ComponentChildren }) => {
  const [step, setStep] = useState<Step | null>(null)
  const [personalData, setPersonalData] = useState<PersonalData>(userValues)
  const [undertaker, setUndertaker] = useState<Undertaker | null>(undertakerValues)
  const [orderId, setOrderId] = useState<string | null>(null)
  const [pendingPayment, setPendingPayment] = useState<string | null>(null)

  const getIndexOfStep = (step: Step): number => {
    for (let i = 0; i < steps.length; i++) {
      if (step == steps[i]) return i
    }

    return 0
  }

  const currentIndex = useMemo(() => {
    if (step) return getIndexOfStep(step)

    return 0
  }, [step])

  const onPopState = (e: PopStateEvent) => {
    const targetIndex = e.state?.index

    if (targetIndex != null) {
      if (
        targetIndex == 0 ||
        (targetIndex == 1 && personalData != null) ||
        (targetIndex == 2 && undertaker != null) ||
        (targetIndex == 3 && orderId != null)
      ) {
        setStep(steps[targetIndex])

        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  useEffect(() => {
    window.onpopstate = onPopState

    if (step) document.title = `${step.name} - Meine Erde`
  }, [step])

  const changeStep = (step: Step) => {
    history.pushState(
      {
        index: getIndexOfStep(step)
      },
      ''
    )

    setStep(step)
  }

  useEffect(() => {
    let targetStep = 0

    const clearAfter = localStorage.getItem('clearAfter')

    if (clearAfter != null && parseInt(clearAfter) < Date.now()) {
      localStorage.clear()
    }

    const personalDataFromLocalStorage = localStorage.getItem('personalData')
    if (personalDataFromLocalStorage != null) {
      const mergedData = { ...userValues, ...JSON.parse(personalDataFromLocalStorage) }
      setPersonalData(mergedData)
      targetStep = 1
    }

    const undertakerFromLocalStorage = localStorage.getItem('undertaker')
    if (undertakerFromLocalStorage != null) {
      const mergedData = { ...undertakerValues, ...JSON.parse(undertakerFromLocalStorage) }
      setUndertaker(mergedData)
      targetStep = 2
    }

    const orderIdFromLocalStorage = localStorage.getItem('orderId')
    if (orderIdFromLocalStorage != null) {
      setOrderId(orderIdFromLocalStorage)
      targetStep = 3
    }

    setStep(steps[targetStep])

    for (let i = 0; i <= targetStep; i++)
      history.pushState(
        {
          index: i
        },
        ''
      )
  }, [])

  const storePersonalData = (data: PersonalData) => {
    localStorage.setItem('personalData', JSON.stringify(data))
    setPersonalData(data)
  }

  const storeUndertaker = (data: Undertaker | null) => {
    if (data == null) localStorage.removeItem('undertaker')
    else localStorage.setItem('undertaker', JSON.stringify(data))

    setUndertaker(data)
  }

  const storeOrderId = (id: string | null) => {
    if (id == null) localStorage.removeItem('orderId')
    else localStorage.setItem('orderId', id)

    setOrderId(id)
  }

  const values: SetupProps = {
    currentStep: step,
    currentIndex,
    setStep: changeStep,

    personalData,
    undertaker,
    orderId,
    pendingPayment,
    setPersonalData: storePersonalData,
    setUndertaker: storeUndertaker,
    setOrderId: storeOrderId,
    setPendingPayment
  }

  return <SetupContext.Provider value={values}>{children}</SetupContext.Provider>
}

export const useSetup = () => useContext(SetupContext)

import { Fragment, FunctionComponent, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import {
  ContentPage,
  ContentPageWrapper,
  InputForm,
  InputItem,
  NoResultsContainer,
  SearchIcon,
  SearchResult,
  SkipServiceContainer,
  SkipServiceField
} from '@components/screen/styles'
import ContinueButtonCircular from '@components/screen/continueButtonCircular'
import { LoadingSpinner } from '@components/screen/loadingSpinner'
import { MobileFooter } from '@components/screen/mobileFooter'

export type Undertaker = {
  hubspotId: string | null
  name: string
  street?: string
  postalCode: string
  distance?: number
  city: string
}

const SearchResultItem: FunctionComponent<{
  onClick: (undertaker: Undertaker) => void
  undertaker: Undertaker
  checked: boolean
}> = ({ onClick, undertaker, checked }) => {
  return (
    <SearchResult onClick={() => onClick(undertaker)}>
      <div>
        <b>{undertaker.name}</b>
        <small>
          <span>{undertaker.distance?.toFixed(1)} km</span>
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 18C7 18 14 13.0909 14 7.36364C14 5.41068 13.2625 3.53771 11.9497 2.15676C10.637 0.775809 8.85652 0 7 0C5.14348 0 3.36301 0.775809 2.05025 2.15676C0.737498 3.53771 0 5.41068 0 7.36364C0 13.0909 7 18 7 18ZM10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z"
              fill="#B9B28C"
            />
          </svg>
          {undertaker.street}, {undertaker.postalCode} {undertaker.city}
        </small>
      </div>
      <ul>
        <input type="radio" name="test" id="" checked={checked} />
        <li />
      </ul>
    </SearchResult>
  )
}

const Search: FunctionComponent = () => {
  const [query, setQuery] = useState('')
  const [serviceSelected, setServiceSelected] = useState<Undertaker | null>(null)

  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<Undertaker[]>([])

  const [lastInput, setLastInput] = useState<number>(0)

  const loadResults = async () => {
    setLoading(true)

    const result = await fetch(`${process.env.APIHOST}/undertaker/${query}`)
    const data = await result.json()

    setSearchResults(data)
    setLoading(false)
  }

  useEffect(() => {
    if (query.length < 3) return

    setServiceSelected(null)

    if (lastInput != 0) clearTimeout(lastInput)

    setLastInput(
      window.setTimeout(() => {
        setLastInput(0)
        loadResults()
      }, 200)
    )
  }, [query])

  const getUrl = (undertaker: Undertaker | null) => {
    return `https://rechner.signal-iduna.de/ui/sterbegeldversicherung/beitrag?utm_medium=partner&utm_source=meineerde&utm_campaign=kooperation&utm_content=link${
      undertaker?.hubspotId ? `&utm_term=${serviceSelected?.hubspotId}` : ''
    }`
  }

  return (
    <ContentPageWrapper>
      <ContentPage>
        <h1>Beitrag berechnen für die SIGNAL IDUNA Sterbegeldversicherung</h1>
        <p>
          <b>Möchten Sie einen Bestatter wählen?</b>
          Sie haben die Möglichkeit für Ihre Sterbegeldversicherung einen Bestatter in Ihrer Nähe zu
          suchen, der bereits mit Meine Erde zusammenarbeitet. Sie können auch ohne Auswahl eines
          Bestatters fortfahren.
        </p>
        <InputForm>
          <InputItem>
            <input
              type="text"
              placeholder="Postleitzahl oder Ort"
              value={query}
              onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
            />
            {loading ? (
              <LoadingSpinner strokeColor="var(--sage)" size={24} />
            ) : (
              <SearchIcon
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M19 19L14.65 14.65"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </SearchIcon>
            )}
          </InputItem>
          {query.length > 0 &&
            (searchResults.length > 0 ? (
              searchResults.map((item: Undertaker) => (
                <SearchResultItem
                  undertaker={item}
                  onClick={() => setServiceSelected(item)}
                  checked={serviceSelected != null && serviceSelected.hubspotId == item.hubspotId}
                />
              ))
            ) : (
              <>
                <NoResultsContainer>
                  <h2>Kein Partner in Ihrer Nähe</h2>
                  <p>
                    Wir haben leider noch keinen Bestatter in Ihrer Nähe zu suchen, der bereits mit
                    Meine Erde zusammenarbeitet. Sie können auch ohne Auswahl eines Bestatters
                    fortfahren.
                  </p>
                </NoResultsContainer>
              </>
            ))}
        </InputForm>
        <MobileFooter />
        <ContinueButtonCircular
          active={serviceSelected != null}
          href={serviceSelected ? getUrl(serviceSelected) : undefined}
        />
      </ContentPage>
      <SkipServiceContainer>
        <SkipServiceField href={getUrl(null)}>Ohne Bestatter fortfahren</SkipServiceField>
      </SkipServiceContainer>
    </ContentPageWrapper>
  )
}

export default Search

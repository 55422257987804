import { FunctionComponent, h } from 'preact'
import {
  BenefitItem,
  BenefitList,
  InsuranceLogo,
  SidebarContainer,
  SidebarLegalText,
  SidebarLogo
} from './styles'

const InsuranceSidebar: FunctionComponent = () => {
  return (
    <SidebarContainer alteredPadding={true}>
      <SidebarLogo src="/assets/logo.svg" alteredAppearance={true} />
      <BenefitList>
        <BenefitItem>
          <i>
            <img src="/assets/checkmark-white.svg" />
          </i>
          <span>
            Volle Versicherungs-
            <br />
            leistungen nach 24 Monaten
          </span>
        </BenefitItem>
        <BenefitItem>
          <i>
            <img src="/assets/checkmark-white.svg" />
          </i>
          <span>
            Keine Gesundheits-
            <br />
            prüfung
          </span>
        </BenefitItem>
        <BenefitItem>
          <i>
            <img src="/assets/checkmark-white.svg" />
          </i>
          <span>Garantierte Beitragsstabilität</span>
        </BenefitItem>
      </BenefitList>
      <InsuranceLogo src="/assets/signalIduna.svg" />
      <SidebarLegalText>
        © 2022 Circulum Vitae GmbH ·{' '}
        <a href="https://www.meine-erde.de/impressum" target="_blank">
          Impressum
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/datenschutz" target="_blank">
          Datenschutz
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/agb" target="_blank">
          Geschäftsbedingungen
        </a>
      </SidebarLegalText>
    </SidebarContainer>
  )
}

export default InsuranceSidebar

import styled from 'styled-components'
import { mediaBreakpoints } from '@components/globalStyles'

export const SidebarContainer = styled.div<{
  alteredPadding?: boolean
}>`
  background: var(--dark-background);
  color: var(--white);
  flex: 1;
  max-width: 360px;
  padding: 56px 40px 32px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    height: 72px;
    min-height: 72px;
    max-width: none;
    flex: unset;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`

export const SidebarLogo = styled.img<{
  alteredAppearance?: boolean
}>`
  height: 32px;
  margin-bottom: ${(props) => (props.alteredAppearance ? 88 : 105)}px;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    ${(props) =>
      props.alteredAppearance
        ? `margin-bottom: 0; height: 24px;`
        : `display: none;`}
  }
`

export const SidebarSpacer = styled.div`
  flex: 1
`

export const SidebarListItem = styled.li<{
  active?: boolean
  current?: boolean
}>`
  display: flex;
  align-items: center;
  color: var(${(props) => (props.active ? '--white' : '--pastel-green')});

  i {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(${(props) => (props.active ? '--white' : '--pastel-green')});
    margin-right: 20px;
    color: var(--dark-background);
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
  }

  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    ${(props) => !props.current && `display: none;`}

    & + & {
      margin-top: 0;
    }

    & + &:before {
      display: none;
    }
  }
`

export const SidebarList = styled.ul`
  list-style: none;
  flex: 1;

  li + li {
    margin-top: 16px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 8px;
      background: var(--pastel-green);
      position: absolute;
      bottom: 100%;
      margin-bottom: 4px;
      left: 15px;
    }
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    flex: 0;
  }
`

export const BenefitList = styled.ul`
  flex: 1;
  list-style: none;

  li + li {
    margin-top: 24px;
  }

  @media (max-width: ${mediaBreakpoints.mobile}) {
    display: none;
  }
`

export const BenefitItem = styled.li`
  display: flex;
  align-items: center;

  i {
    height: 24px;
    width: 24px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  span {
    flex: 1;
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    max-width: 190px;
  }
`

export const InsuranceLogo = styled.img`
  height: 86px;
  object-fit: contain;
  object-position: center left;
  margin-bottom: 40px;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    display: none;
  }
`

export const SidebarLegalText = styled.p`
  color: var(--pastel-green);
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${mediaBreakpoints.mobile}) {
    display: none;
  }
`
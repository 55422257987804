import { FunctionComponent, h } from 'preact'
import steps from '@utils/steps'
import { useSetup } from '@components/context/setupProvider'
import {
  SidebarLegalText,
  SidebarContainer,
  SidebarList,
  SidebarListItem,
  SidebarLogo
} from './styles'

const BasicFlowSidebar: FunctionComponent = () => {
  const { currentIndex, setStep } = useSetup()

  return (
    <SidebarContainer>
      <SidebarLogo src="/assets/logo.svg" />
      <SidebarList>
        {steps.map((item, index) => (
          <SidebarListItem
            onClick={() => (index < currentIndex ? setStep(steps[index]) : null)}
            current={currentIndex == index}
            active={currentIndex == index || index < currentIndex}>
            <i>{index < currentIndex ? <img src="/assets/checkmark.svg" /> : index + 1}</i>
            <span>{item.name}</span>
          </SidebarListItem>
        ))}
      </SidebarList>
      <SidebarLegalText>
        © 2022 Circulum Vitae GmbH ·{' '}
        <a href="https://www.meine-erde.de/impressum" target="_blank">
          Impressum
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/datenschutz" target="_blank">
          Datenschutz
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/agb" target="_blank">
          Geschäftsbedingungen
        </a>
      </SidebarLegalText>
    </SidebarContainer>
  )
}

export default BasicFlowSidebar

import { FunctionComponent, h } from 'preact'
import { useEffect } from 'preact/hooks'

import steps from '@utils/steps'
import { useSetup } from '@components/context/setupProvider'
import { ContentPage } from '@components/screen/styles'
import { MobileFooter } from '@components/screen/mobileFooter'

const ValidatePayment: FunctionComponent = () => {
  const { orderId, setStep, setPendingPayment } = useSetup()

  const validatePayment = async () => {
    const response = await fetch(`${process.env.APIHOST}/order/${orderId}`)

    if (response.ok) {
      const json = await response.json()

      if (json.status === 'paid' || json.status === 'authorized') setStep(steps[4])
      else {
        setPendingPayment(json.checkoutUrl)
        setStep(steps[2])
      }
    }
  }

  useEffect(() => {
    validatePayment()
  }, [])

  return (
    <ContentPage>
      <h1>Zahlung</h1>
      <p>Ihre Zahlung wird überprüft, bitte warten Sie einen Moment.</p>
      <MobileFooter />
    </ContentPage>
  )
}

export default ValidatePayment

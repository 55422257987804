type Step = {
  identifier: string
  name: string
}

const steps: Step[] = [
  {
    identifier: 'data',
    name: 'Persönliche Daten'
  },
  {
    identifier: 'service',
    name: 'Bestatter auswählen'
  },
  {
    identifier: 'summary',
    name: 'Zusammenfassung'
  },
  {
    identifier: 'payment',
    name: 'Zahlung'
  },
  {
    identifier: 'confirmation',
    name: 'Bestätigung'
  }
]

export default steps
export type { Step }

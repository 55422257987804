export function getSalutation(salutation: string) {
  switch (salutation) {
    case 'm':
      return 'Herr'
    case 'f':
      return 'Frau'
    default:
      return ''
  }
}

export function getBirhday(birthday: string) {
  const date = new Date(birthday)

  return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(
    -2
  )}.${date.getFullYear()}`
}

import { h, FunctionComponent } from 'preact'
import Router from 'preact-router'

import GlobalStyles, { Content, Wrapper } from '@components/globalStyles'
import BasicFlowSidebar from '@components/sidebar/basicFlowSidebar'
import { SetupProvider, useSetup } from '@components/context/setupProvider'
import InsuranceSidebar from '@components/sidebar/insuranceSidebar'
import BareboneSidebar from '@components/sidebar/bareboneSidebar'

import PersonalData from '@routesBasic/personalData'
import ServiceSearch from '@routesBasic/serviceSearch'
import Summary from '@routesBasic/summary'
import Confirmation from '@routesBasic/confirmation'
import ValidatePayment from '@routesBasic/validatePayment'

import Search from '@routesInsurance/search'

import Page404 from '@routes/page404'

const SetupRouter: FunctionComponent = () => {
  const { currentStep } = useSetup()

  switch (currentStep?.identifier) {
    case 'data':
      return <PersonalData />
    case 'service':
      return <ServiceSearch />
    case 'summary':
      return <Summary />
    case 'payment':
      return <ValidatePayment />
    case 'confirmation':
      return <Confirmation />
    default:
      return null
  }
}

const BasicFlow: FunctionComponent = () => (
  <SetupProvider>
    <Wrapper>
      <BasicFlowSidebar />
      <Content>
        <SetupRouter />
      </Content>
    </Wrapper>
  </SetupProvider>
)

const InsuranceFlow: FunctionComponent = () => (
  <Wrapper>
    <InsuranceSidebar />
    <Content>
      <Search />
    </Content>
  </Wrapper>
)

const Page404Flow: FunctionComponent = () => (
  <Wrapper>
    <BareboneSidebar />
    <Content center={true}>
      <Page404 />
    </Content>
  </Wrapper>
)

const App = () => {
  return (
    <div id="app">
      <GlobalStyles />
      <Router>
        <BasicFlow path="/" />
        <InsuranceFlow path="/sterbegeldversicherung" />
        <Page404Flow default />
      </Router>
    </div>
  )
}

export default App

import { FunctionComponent, h } from 'preact'
import { SidebarContainer, SidebarLegalText, SidebarLogo, SidebarSpacer } from './styles'

const BareboneSidebar: FunctionComponent = () => {
  return (
    <SidebarContainer alteredPadding={true}>
      <SidebarLogo src="/assets/logo.svg" alteredAppearance={true} />
      <SidebarSpacer />
      <SidebarLegalText>
        © 2022 Circulum Vitae GmbH ·{' '}
        <a href="https://www.meine-erde.de/impressum" target="_blank">
          Impressum
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/datenschutz" target="_blank">
          Datenschutz
        </a>{' '}
        ·{' '}
        <a href="https://www.meine-erde.de/agb" target="_blank">
          Geschäftsbedingungen
        </a>
      </SidebarLegalText>
    </SidebarContainer>
  )
}

export default BareboneSidebar

import { FunctionalComponent } from 'preact'
import {
  LoadingSpinnerCirlce,
  LoadingSpinnerContainer,
  LoadingSpinner as LoadingSpinnerComponent
} from './styles'

export const LoadingSpinner: FunctionalComponent<{ strokeColor?: string; size?: number }> = ({
  strokeColor,
  size
}) => (
  <LoadingSpinnerContainer size={size}>
    <LoadingSpinnerComponent viewBox="0 0 50 50">
      <LoadingSpinnerCirlce strokeColor={strokeColor} cx="25" cy="25" r="20" />
    </LoadingSpinnerComponent>
  </LoadingSpinnerContainer>
)

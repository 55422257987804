import { FunctionComponent } from 'preact'
import App from '@components/app'

let wrapper

if (process.env.SSR) {
  wrapper = (App: FunctionComponent) => {
    const { ServerStyleSheet, StyleSheetManager } = require('styled-components')

    const sheet = new ServerStyleSheet()
    const StyleTags = () => sheet.getStyleElement()

    return () => [
      <StyleSheetManager sheet={sheet.instance}>
        <App />
      </StyleSheetManager>,
      <StyleTags />
    ]
  }
} else {
  wrapper = (app: FunctionComponent) => app
}

export default wrapper(App)
